.third-page {
    padding: 51px;
    background-color: #fdf6f3;
}

.developed-by-special {
    /* height: 89px; */
    color: #D755A2;
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 60px;
    text-align: center;
}

.group {
    height: auto;
    margin-top: 35px;
    margin-bottom: 72px;
}

.rectangle {
    height: auto;
    padding: 4px 16px;
    border-radius: 100px;
    background-color: #ffffff;
    box-shadow: 0 7px 10px 0 rgba(88, 61, 105, 0.1);
    display: flex;
}

.img-avatar-picture {
    height: 60px;
    width: 60px;
    margin-top: 12px;
    border-radius: 50%;
}

.right-column {
    margin-top: 14.5px;
    margin-bottom: 14.5px;
    margin-left: 10px;
    text-align: left;
}

.name-1 {
    height: 20px;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 19px;
}

.text-1 {
    min-height: 40px;
    color: #908d95;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}

.read-more-line {
    height: 1px;
    width: 128px;
    background-color: #9b989f;
}

.paragraph-1 {
    height: 131px;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    text-align: left;
    margin-top: 33.6px;
}

.paragraph-1-more {
    height: auto;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    text-align: left;
    margin-top: 33.6px;
}

.paragraph-bigger-1 {
    height: 131px;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    text-align: left;
    margin-top: 33.6px;
}

.paragraph-bigger-1-more {
    height: auto;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    text-align: left;
    margin-top: 33.6px;
}

.paragraph-2 {
    height: 100px;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    text-align: left;
    margin-top: 33.6px;
}

.paragraph-2-more {
    height: auto;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    overflow: hidden;
    text-align: left;
    margin-top: 33.6px;
}

.read-more-1 {
    height: 21px;
    opacity: 0.6;
    display: flex;
    text-align: center;
    padding: 6px 20px;
    justify-content: center;
}

.read-more-2 {
    height: 21px;
    opacity: 0.6;
    display: flex;
    text-align: center;
    padding: 6px 20px;
    justify-content: center;
}

.read-more-hiden {
    display: none;
}

.button-content {
    color: #9b989f;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
}

.take-test-3 {
    margin-left: 0px;
}

@media (max-width: 475px) {
    .third-page {
        padding: 35px 15px;
    }
}

@media (max-width: 320px) {

    .right-column {
        margin-top: 14.5px;
        margin-bottom: 14.5px;
        margin-left: 10px;
        text-align: left;
    }

    .name-1 {
        height: 20px;
        color: #5f5f5f;
        font-family: Montserrat;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
    }

    .text-1 {
        min-height: 40px;
        color: #908d95;
        font-family: Montserrat;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 18px;
    }
}

@media (max-width: 280px) {
    .rectangle {
        height: auto;
        padding: 0 10px;
        border-radius: 100px;
        background-color: #ffffff;
        box-shadow: 0 7px 10px 0 rgba(88, 61, 105, 0.1);
        display: flex;
        margin: 0 -20px;
    }

    .right-column {
        margin-top: 14.5px;
        margin-bottom: 14.5px;
        margin-left: 10px;
        text-align: left;
    }

    .name-1 {
        height: 20px;
        color: #5f5f5f;
        font-family: Montserrat;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
    }

    .text-1 {
        min-height: 40px;
        color: #908d95;
        font-family: Montserrat;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 18px;
    }
}