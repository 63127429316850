div#imageRef {
    position: fixed;
    width: 1024px;
    background: white;
    z-index: -1;
    bottom: 100vh;
}

.title-container {
    background: #1777c1;
    color: white;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    font-size: 48px;
}

span.title-icon {
    font-size: 48px;
    background: #d1579b;
    border-radius: 50%;
    padding: 20px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    box-sizing: content-box;
    margin-right: 16px;
    text-align: center;
}

.result-body-container {
    background: white;
    padding: 16px;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
}

.chart-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 16px;
    color: #d755a2;
}

.strength-bar {
    height: 50px;
    width: 2px;
    background-color: #D755A2;
    margin-top: 2px;
}

.strength-title {
    font-size: 24px;
    text-align: left;
    margin-bottom: 16px;
    color: grey;
    /* border-left: 1px solid grey; */
    padding-left: 16px;
}

.result-skills {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.chart-block {
    width: 60%;
}

img.result-logo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 24px;
}