.wwt-card {
    border-radius: 16px;
    padding: 12px 0;
    background: white;
}

.wwt-input {
    background: #f5f6f8;
    border: 0;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
}

.wwt-input:focus {
    border: 0;
}

table.company-contacts {
    background-color: white;
}

table.company-contacts th {
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    border: none;
}

table.company-contacts td {
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
}

#qInProgressBtn {
    background-color: #bc5bb1 !important;
}

.contact-update-confirmation-dialog {
    text-align: start;
}

.contact-update-confirmation-dialog .dialog-title {
    font-size: 24px;
    font-weight: bold;
    margin: 8px 0;
}