.contacts-page {
    padding-bottom: 100px;
}

.contacts-header {
    /* height: 90px; */
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
}

.contact-invite-button {
    box-sizing: border-box;
    height: 41px;
    width: 131px;
    border: 1px solid rgba(144, 141, 149, 0.3);
    border-radius: 40px;
    padding-top: 12px;
    background-color: #00CFD7;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 15px;
    text-align: center;
}

.contact-nav-button {
    height: 50px;
    border-radius: 40px;
    background-color: #FFFFFF;
    margin: -115px 20px 20px 20px;
    padding: 11px 19px;
    justify-content: space-between;
    display: flex;
}

.contacts-header-content {
    height: 24px;
    width: 250px;
    color: #908D95;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin: auto;
}

.contact-person {
    padding: 15px 20px;
    text-align: left;
    display: flex;
    align-items: center;
}

.contact-person-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    color: white;
}

.contact-person-context {
    margin-left: 10px;
    width: 100%;
}

.contact-person-name {
    color: #5F5F5F;
    font-family: Montserrat;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 19px;
}

.contact-person-state {
    color: #908D95;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 3px;
}

.matching-button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.matching-button {
    box-sizing: border-box;
    height: 41px;
    width: 131px;
    border: 1px solid rgba(144, 141, 149, 0.3);
    border-radius: 40px;
    padding-top: 12px;
    background-color: #00CFD7;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 15px;
    text-align: center;
}

.matching-refuse {
    box-sizing: border-box;
    height: 41px;
    width: 131px;
    border: 1px solid rgba(144, 141, 149, 0.3);
    border-radius: 40px;
    padding-top: 12px;
    background-color: #FFFFFF;
    color: #908D95;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 15px;
    text-align: center;
}

.linkedin-icon {
    height: 18px;
    width: 18px;
    margin: 0 7px;
}

.contacts-filter-block {
    width: 100%;
}

.contacts-filter-block .filter-input-icon {
    font-size: 24px;
    color: grey;
    margin-left: 8px;
}

.contacts-filter-block .filter-input {
    border: 0;
    outline: 0;
}

.top-message {
    position: absolute;
    top: 2rem;
}

.bottom-message {
    position: absolute;
    bottom: 3rem;
}

@media (max-width: 425px) {
    .top-message {
        top: 1rem;
    }

    .bottom-message {
        bottom: 2rem;
    }
}

.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    cursor: pointer;
}