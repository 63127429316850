.graph-top-row {
  text-align: center !important;
  width: 100%;
  height: auto;
}

line {
  fill: lime;
  stroke-width: 1px;
  stroke: #d3d3d3;
}

.axis-top {
  box-sizing: border-box;
  height: 36px;
  width: 250px;
  border: 1px solid #908D95;
  opacity: 0.8;
  border-radius: 10px;
  color: #A5A2A9;
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0;
  /* line-height: 14px; */
  text-align: center;
  padding: 3px;
  margin: auto;
}

.graph {
  height: 100%;
  width: 100%;
}

.axis-left {
  /* box-sizing: border-box; */
  height: 200px;
  width: 60px;
  border: 1px solid #908D95;
  opacity: 0.8;
  border-radius: 10px;
  /* transform: rotate(90deg); */
  color: #A5A2A9;
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0;
  /* line-height: 14px; */
  text-align: center;
  justify-content: center;
  /* padding: 2px 15px; */
  /* transform: rotate(90deg); */
  position: relative;

  /* box-sizing: border-box;
  */
  height: 36px;
  width: 250px;
  border: 1px solid #908D95;
  opacity: 0.8;
  border-radius: 10px;
  transform: rotate(90deg);
  color: #A5A2A9;
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0;
  /* line-height: 14px; */
  text-align: center;
  justify-content: center;
  /* padding: 2px 15px; */
  /* transform: rotate(90deg); */
  position: absolute;
  left: -125px;
  background: white;
  font-size: 1rem;

}

.axis-right {
  /* box-sizing: border-box; */
  height: 200px;
  width: 60px;
  border: 1px solid #908D95;
  opacity: 0.8;
  border-radius: 10px;
  /* transform: rotate(-90deg); */
  color: #A5A2A9;
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0;
  /* line-height: 14px; */
  text-align: center;
  justify-content: center;
  /* padding: 2px 15px; */

  height: 36px;
  width: 250px;
  border: 1px solid #908D95;
  opacity: 0.8;
  border-radius: 10px;
  transform: rotate(-90deg);
  color: #A5A2A9;
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0;
  /* line-height: 14px; */
  text-align: center;
  justify-content: center;
  /* padding: 2px 15px; */
  position: absolute;
  right: -125px;
  background: white;
}

.axis-bottom {
  box-sizing: border-box;
  height: 36px;
  width: 250px;
  border: 1px solid #908D95;
  opacity: 0.8;
  border-radius: 10px;
  color: #A5A2A9;
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: 0;
  /* line-height: 14px; */
  text-align: center;
  padding: 3px;
  margin: auto;
}

.graph-explain-content {
  /* height: 20px; */
  /* width: 272px; */
  color: #908D95;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  /* line-height: 18px; */
  margin-bottom: 15px;
}

.graph-oval-1 {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 1px solid #006CB5;
  border-radius: 50%;
  background: radial-gradient(circle, #A6E2FF 0%, #006CB5 100%);
  margin-bottom: 10px;
}

.graph-oval-content {
  /* height: 20px; */
  color: #908D95;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 10px;
}

.graph-oval-2 {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 1px solid #D755A2;
  border-radius: 50%;
  background: radial-gradient(circle, #FFA3D9 0%, #D755A2 100%);
}