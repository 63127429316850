.homepage {
    /* scroll-behavior: smooth; */
    background: linear-gradient(135.13deg, #006cb5 0%, #3e88d4 100%);
}

.header {
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #ffffff;
}

.header .header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    /* height: 40px;
    width: 167.88px; */
    height: 47px;
    width: 268px;
    background-color: #ffffff;
}

.wwt-btn.myprofile {
    width: unset !important;
    padding: 12px 24px;
}

#myprofile {
    width: unset;
    padding: 12px 24px;
}

.profile-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.main-page {
    padding: 39px 52px 50px 51px;
    text-align: center;
    /* padding-bottom: 50px; */
    background: linear-gradient(135.13deg, #006cb5 0%, #3e88d4 100%);
}

.how-work {
    box-sizing: border-box;
    /* height: 41px; */
    /* height: 100%; */
    border: 1px solid #c4bcce;
    border-radius: 40px;
    /* margin-top: 20px; */
    color: #ffffff;
    z-index: 10000;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1.5px;
    line-height: 13px;
    padding: 16px;
    text-transform: uppercase;
    width: 150px;
}

.second-page {
    /* height: 961px; */
    padding-top: 48px;
    padding-right: 5px;
    padding-bottom: 50px;
    background-color: #ffffff;
}

.how-it-works {
    height: 200px;
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 60px;
    text-align: center;
}

.step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.step-01 {
    /* margin-left: 5px;
    margin-right: 17px; */
}

.second-page .step .step-img {
    width: 80px;
    height: 80px;
}

.step-content {
    display: flex;
    flex-direction: column;

}

.step-title {
    color: #5F5F5F;
    font-size: 18px;
    font-weight: bold;
    margin: 8px 16px;
    text-align: center;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-description {
    color: #908d95;
    font-family: Montserrat;
    font-size: 14px;
    margin: 16px;
}

.take-the-test {
    height: 30px;
    color: #3c3c3b;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    /* margin-top: 3.8px;
    margin-left: 140px;
    margin-right: 0; */
    text-align: left;
}

.first-content {
    display: flex;
    /* padding-left: 6px; */
    text-align: center;
}

.first-content>div {
    text-align: center;
}

.group-1 {
    height: 72px;
    width: 79px;
    margin-bottom: 27px;
    margin-left: 22px;
}

.take-a-15-minutes-te {
    width: 188px;
    color: #908d95;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 33px;
    text-align: left;
}

.take-a-15-minutes-te>b {
    font-weight: bold;
}

.line-home {
    height: 1px;
    width: 32px;
    background-color: #a3acbd;
    margin-top: 6px;
    margin-left: 145px;
    margin-bottom: 12px;
}

.step-02 {
    /* margin-top: 26px;
    margin-left: 32px; */
}

.learn-about-your-rel {
    color: #3c3c3b;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 5.8px;
    margin-left: 113px;
    text-align: left;
    padding-right: 5px;
}

.second-content {
    /* margin-top: 13px; */
    display: flex;
}

.group-2 {
    height: 76px;
    width: 78px;
}

.discover-your-domina {
    width: 185px;
    color: #908d95;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 35px;
    text-align: left;
}

.discover-your-domina>b {
    font-weight: 900;
}

.step-03 {
    /* margin-top: 28px;
    margin-left: 14px; */
}

.match-with-your-frie {
    color: #3c3c3b;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 3.8px;
    margin-left: 129px;
    text-align: left;
}

.third-content {
    display: flex;
    /* margin-top: 16px; */
}

.group-3 {
    height: 86px;
    width: 76px;
    margin-left: 20px;
}

.take-test-2 {
    height: 60px;
    border-radius: 40px;
    margin-left: 51px;
    margin-right: 51px;
    background-color: #00cfd7;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 13px;
    padding: 25px;
}

.login-button {
    margin: 0 50px;
}

.homepage .wwt-btn {
    width: 270px;
}

@media (max-width: 425px) {
    .header {
        padding: 0;
    }

    .logo {
        width: unset;
    }

    .login-button {
        margin: 0 15px !important;
    }

    .how-it-works {
        height: 70px;
        color: #3C3C3B;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
    }

    .step-img {
        position: absolute;
        left: 20px;
    }

    .step-content {
        text-align: left;
        margin-left: 100px;
        flex-direction: column;
    }

    .step-title {
        justify-content: flex-start;
        height: unset;
    }

    .step-content>div {
        text-align: left;
    }
}

@media (max-width: 475px) {
    .main-page {
        padding: 0;
        padding-bottom: 50px;
    }

    .homepage .wwt-btn {
        width: 220px;
    }
}

@media (max-width: 320px) {

    .myprofile {
        box-sizing: border-box;
        height: 40px;
        width: fit-content;
        border: 1px solid #ffffff;
        border-radius: 40px;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 0;
        padding: 11px 10px;
        line-height: 15px;
        outline: none !important;
    }

    /* .step-01 {
        margin-left: -15px;
        margin-right: 17px;
    } */

    /* .step-02 {
        margin-top: 26px;
        margin-left: 12px;
    } */

    /* .step-03 {
        margin-top: 28px;
        margin-left: -6px;
    } */
}

@media (max-width: 280px) {
    .logo {
        height: 40px;
        width: 130px;
        color: #ffffff;
    }

    .myprofile {
        box-sizing: border-box;
        height: 40px;
        width: fit-content;
        border: 1px solid #ffffff;
        border-radius: 40px;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 0;
        padding: 11px 10px;
        line-height: 15px;
        outline: none !important;
    }
}