.accordion {
  padding-top: 10px;
}

.accordion-row-1 {
  display: flex;
  justify-content: space-between;
}

.accordion-title {
  height: 20px;
  color: #5F5F5F;
  font-family: Poppins;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 3px;
}

.accordion-icon-frame {
  box-sizing: border-box;
  height: 31px;
  width: 31px;
  border: 1px solid #908D95;
  opacity: 0.3;
  border-radius: 40px;
  text-align: center;
  padding: 2px;
}

.accordion-content {
  color: #5F5F5F;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 20px;
}

@media (max-width: 280px) {
  .accordion-title {
    width: 190px;
  }
}