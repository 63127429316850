body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: local('Poppins'), url(./fonts/Poppins-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: local('Poppins'), url(./fonts/Poppins-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: local('Poppins'), url(./fonts/Poppins-ExtraBold.otf) format('opentype');
}