.compare-header {
  height: 110px;
  background: linear-gradient(135.13deg, #006CB5 0%, #3E88D4 100%);
  display: flex;
  text-align: left;
}

.back-icon {
  height: 24px;
  width: 24px;
  margin-top: 43px;
  margin-left: 24px;
  cursor: pointer;
  font-size: 24px;
  color: white;
}

.compare-header-text {
  height: 26px;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 13px;
  margin-top: 42px;
}


.compare-softskill-text {
  background-color: transparent;
  color: #0a66c2;
  box-shadow: inset 0 0 0 1px #0a66c2;
  font-size: 1.2rem;
  min-height: 2.2rem;
  padding: .6rem 1.2rem;
  line-height: 1.2rem;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: 167ms;
  align-items: center;
  border: none;
  box-sizing: border-box;
  font-weight: 600;
  font-family: inherit;
  display: inline-flex;
  justify-content: center;
  min-width: 6.4rem;
  max-width: 480px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-radius: 1.59rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin: .2rem;
}

.more-icon {
  height: 24px;
  width: 24px;
  margin-top: 43px;
  position: absolute;
  right: 24px;
  font-size: 24px;
  color: white;
}

.nav-button {
  height: 60px;
  border-radius: 40px;
  background-color: #FFFFFF;
  margin-top: -120px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 15px 19px;
  justify-content: space-between;
  display: flex;
}

.compare-shadow {
  height: 90px;
  background: linear-gradient(180deg, rgba(157, 157, 157, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
}

.nav-profile {
  height: 30px;
  display: flex;
  cursor: pointer;
}

.profile-icon-nav {
  height: 24px;
  width: 24px;
  margin-top: 3px;
}

.profile-nav-text {
  height: 30px;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin-left: 5px;
  margin-top: 5px;
}

.nav-compare {
  height: 30px;
  display: flex;
  cursor: pointer;
}

.compatibility-icon {
  height: 24px;
  width: 24px;
  margin-top: 3px;
}

.compatibility-nav-text {
  height: 30px;
  color: #D755A2;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 5px;
  margin-left: 5px;
}

.nav-contacts {
  height: 30px;
  display: flex;
  cursor: pointer;
}

.contacts-icon-nav {
  height: 24px;
  width: 24px;
  margin-top: 3px;
}

.contacts-nav-text {
  height: 30px;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 5px;
  margin-left: 5px;
}

.compare-row-1 {
  /* height: 114px; */
  padding: 0 20px;
  text-align: left;
  margin-top: 55px;
}

.compare-row-title {
  height: 24px;
  color: #3C3C3B;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
}

.compare-row-subtitle {
  height: 24px;
  color: #908D95;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: left;
}

.fiber-title {
  text-align: center;
  font-size: 20px;
  color: #908D95;
  margin-top: 16px;
  font-family: 'Montserrat';
}

.fiber-description {
  color: #908D95;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0;
  /* line-height: 18px; */
  padding-top: 20px;
}

.compare-subrect {
  height: 40px;
  margin-top: 15px;
}

.rect-label {
  height: 20px;
  width: 100%;
  color: #5F5F5F;
  font-family: Montserrat;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
}

.compare-rate {
  height: 16px;
  width: 55px;
  opacity: 0.5;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
  margin-top: 3.1px;
}

.down-icon-frame {
  box-sizing: border-box;
  height: 31px;
  width: 31px;
  border: 1px solid rgba(144, 141, 149, 0.3);
  border-radius: 40px;
  margin-top: 3px;
  margin-left: 5px;
}

.compare-down-icon {
  height: 16px;
  width: 16px;
  margin: 6.5px;
}

.compare-line-progress {
  height: 5px;
  width: 90%;
  border-radius: 10px;
  background-color: rgba(144, 141, 149, 0.1);
}

.compare-line-progress-content {
  height: 5px;
  width: 0;
  border-radius: 10px;
  background-color: #D755A2;
  transition: 1s;
}

.compare-row-2 {
  height: 186px;
  margin-top: 50px;
  padding: 0 20px;
  text-align: center;
}

.compare-second-rect {
  height: 130px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-3 {
  height: 60px;
  width: 60px;
  margin: auto;
  border-radius: 50%;
  border: 8px solid #908D9544;
  color: white;
}

.combine-line {
  height: 5px;
  width: 13%;
  border-radius: 10px;
  background-color: rgba(144, 141, 149, 0.3);
  margin: auto;
}

.compare-row-3 {
  /* height: 115px; */
  margin-top: 62px;
  text-align: left;
  padding: 0 20px;
}

.compare-row-4 {
  margin-top: 55px;
  text-align: left;
  padding: 0 20px;
  margin-bottom: 126px;
}

.compare-subrect-1 {
  height: 50px;
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.subrect-content {
  height: 20px;
  width: 100%;
  color: #5F5F5F;
  font-family: Montserrat;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
}

@media (max-width: 320px) {
  .nav-button {
    justify-content: space-around;
  }

  .profile-nav-text {
    display: none;
  }

  .compatibility-nav-text {
    display: none;
  }

  .contacts-nav-text {
    display: none;
  }
}

@media (max-width: 280px) {

  .nav-button {
    padding: 15px 30px;
  }
}