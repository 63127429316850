.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(163, 172, 189, 0.1);
}

.value-details-modal .modal-body {
    padding: 0;
}

.value-details-modal .modal-header {
    background-color: #006CB5;
    color: white
}

table.mission-details th {
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
}

table#myValueMissions th.value {
    padding-left: 8px;
    padding-right: 8px;
}

table.mission-details td {
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
}

.mission-details-action-btns .action-btn:not(:hover) .icon {
    background: white;
    color: grey;
}

.selected-contact-block {
    padding: 8px 16px;
    background: #F6F7F8;
}

.selected-contact-block .action-btn:not(:hover) .icon {
    background: #F6F7F8;
    color: grey;
}