.form-elegant .font-small {
  font-size: 0.8rem;
}

.form-elegant .z-depth-1a {
  -webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
  box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26),
    0 4px 12px 0 rgba(121, 155, 254, 0.25);
}

.form-elegant .z-depth-1-half,
.form-elegant .btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
  box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28),
    0 4px 15px 0 rgba(36, 133, 255, 0.15);
}

.login-modal {
  margin-top: 70px !important;
  padding: 0 5px;
}

.login-modal .modal-content {
  max-height: calc(100vh - 32px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-body {
  padding: 45px 35px !important;
}

.login-modal-container {
  margin: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  max-width: inherit !important;
  margin-right: 16px !important;
}

.take-test {
  height: 60px;
  border-radius: 40px;
  /* margin-top: 40px; */
  background-color: #00cfd7;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 13px;
  padding: 25px;
  text-transform: uppercase;
}

.first-take-test {
  background-color: #d755a2;
}

.sign-in-button {
  height: 50px;
  border-radius: 28px !important;
}

.login-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 900;
  color: #3C3C3B;
}

.social-login-button-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 24px;
}

.login-modal-explanation {
  color: #908d95;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 24px;
}

.modal-bottom {
  margin-top: 24px;
  font-size: 11px;
  font-family: Poppins;
}

.modal-bottom>a {
  color: #908d95;
}

.input-feedback {
  color: rgb(235, 54, 54);
  margin-top: -40px;
  font-size: 14px;
  /* margin-bottom: 20px; */
  text-align: left;
}

.social-login-header {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid grey;
  line-height: 0.1em;
  margin: 2em 0 3em;
}

.linkedin-btn-container .btn-linkedin {
  height: 50px;
  border-radius: 40px;
  width: 100%;
  border: none;
  outline: none !important;
  background-color: #0E76A8;
  color: #ffffff;
  font-size: 12px;
  font-family: Poppins;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 20px;
}

.linkedin-btn-container .btn-linkedin .fa-linkedin {
  font-size: 14px !important;
}

.btn-google {
  height: 50px;
  width: 120px;
  border-radius: 40px !important;
  outline: none !important;
  border: 1px solid #908d95 !important;
  background-color: #ffffff !important;
  color: #908d95;
  box-shadow: none !important;
  text-align: center !important;
  font-family: Poppins;
  font-size: 12px;
  opacity: 1 !important;
}

.btn-google>div {
  display: none;
}

.btn-google>span {
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold !important;
}

.btn-facebook {
  height: 41px;
  width: 120px;
  border-radius: 40px !important;
  outline: none !important;
  border: 1px solid #908d95;
  background-color: #ffffff;
  color: #908d95;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
}

.btn-facebook .fa-facebook-square {
  margin-right: 14px;
  font-size: 14px !important;
}

.fa-google-plus-g {
  margin-left: 15px;
}

@media (max-width: 425px) {
  .login-modal {
    margin-top: 1rem !important;
  }
}

@media (max-width: 280px) {

  .card-body {
    padding: 45px 20px !important;
  }

  .login-title {
    font-family: Poppins;
    font-size: 17px !important;
    font-weight: 900;
    color: #3C3C3B;
  }

  .btn-google {
    height: 41px;
    width: 50px;
    border-radius: 40px !important;
    outline: none !important;
    border: 1px solid #908d95 !important;
    background-color: #ffffff !important;
    color: #908d95;
    box-shadow: none !important;
    text-align: center !important;
  }

  .btn-facebook {
    height: 41px;
    width: 50px;
    border-radius: 40px !important;
    outline: none !important;
    border: 1px solid #908d95;
    background-color: #ffffff;
    color: #908d95;
  }

  .fa-google-plus-g {
    margin-left: 18px;
  }
}

.legal-modal-link .legal-modal-container {
  display: inline;
}

.legal-modal-link .legal-modal-container .legal-modal-btn {
  display: inline;
}

@media (max-width: 320px) {
  .sign-in-button {
    height: auto;
    border-radius: 28px !important;
  }

  .login-title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 900;
    color: #3C3C3B;
  }

  .linkedin-btn-container .btn-linkedin {
    height: 50px;
    border-radius: 40px;
    width: 100%;
    border: none;
    outline: none !important;
    background-color: #0E76A8;
    color: #ffffff;
    font-size: 11px;
    font-family: Poppins;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 24px;
  }

  .btn-facebook {
    height: 41px;
    width: 110px;
    border-radius: 40px !important;
    outline: none !important;
    border: 1px solid #908d95;
    background-color: #ffffff;
    color: #908d95;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
  }

  .btn-google {
    height: 41px;
    width: 110px;
    border-radius: 40px !important;
    outline: none !important;
    border: 1px solid #908d95 !important;
    background-color: #ffffff !important;
    color: #908d95;
    box-shadow: none !important;
    text-align: center !important;
    font-family: Poppins;
    font-size: 12px;
  }

  .fa-google-plus-g {
    margin-left: 10px;
  }

}