.share-results {
  padding: 17px 20px 232px 20px;
}

.share-results-rectangle {
  height: 246px;
  width: 100%;
  border-radius: 1px 40px 1px 40px;
  background-color: #FAB83B;
  display: flex;
  text-align: left;
}

.share-results-left {
  margin-top: 37px;
}

.share-results-comment {
  height: 19px;
  width: 134px;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 33px;
}

.share-results-title {
  height: 24px;
  width: 158px;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 33px;
  margin-top: 12px;
}

.share-results-share-frame {
  display: flex;
  height: 50px;
  width: 200px;
  border-radius: 40px;
  background-color: #006CB5;
  margin: 31px 0 0 22px;
  margin: auto;
  align-items: center;
  padding: 0 15px;
  justify-content: space-around;
}

.share-results-share-frame .btn-linkedin .fa-linkedin {
  font-size: 14px !important;
}

.share-results-invite-frame {
  display: flex;
  height: 50px;
  width: 200px;
  border-radius: 40px;
  background-color: #E87B9B;
  margin: auto;
  align-items: center;
  padding: 0 15px;
  justify-content: space-around;
}

.calltoaction-results-invite-frame {
  display: flex;
  height: 50px;
  width: 270px;
  border-radius: 40px;
  background-color: #E87B9B;
  margin: auto;
  align-items: center;
  padding: 0 15px;
  justify-content: space-around;
}

.results-share-icon {
  height: 16px;
  width: 16px;
}

.results-invite-icon {
  height: 16px;
  width: 16px;
}

.share-results-share-content {
  height: 15px;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin: auto;
}

.share-results-invite-content {
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
}

.social-share-button-text {
  outline: none !important;
  display: block;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 18px !important;
  text-align: center;
  color: #5f5f5f !important;
  font-family: Poppins !important;
}