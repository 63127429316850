.reset-pwd-container {
    width: 100vw;
    height: 100vh;
    background: #f7f7f7;
}

.reset-pwd-block {
    width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 43px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    border: 1px solid lightgrey;
    box-shadow: 2px 2px 8px -4px black;
    border-radius: 4px;
    background: white;
}