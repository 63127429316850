.fourth-page {
    padding: 57px 5px 50px 5px;
    background-color: #ffffff;
}

.why-work-well-togeth {
    min-height: 150px;
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 60px;
    text-align: center;
}

.step-img {
    width: 105px;
    height: 105px;
}


.step-1 {
    margin-top: 52px;
    margin-left: 31px;
    display: flex;
}

.step-1-img {
    height: 79px;
    width: 78px;
    margin-top: 4px;
    margin-left: 4px;
}

.column {
    margin-left: 42px;
}

.recruiters {
    height: 40px;
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
}

.connected-to-a-netwo {
    /* height: 72px; */
    /* width: 188px; */
    color: #908d95;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 10px;
    text-align: left;
}

.sep-line {
    height: 1px;
    width: 32px;
    background-color: #a3acbd;
    margin-top: 12px;
    margin-left: 145px;
    margin-bottom: 12px;
}

.step-2 {
    margin-top: 30px;
    margin-left: 31px;
    display: flex;
}

.step-2-img {
    height: 79px;
    width: 78px;
    margin-top: 35px;
}

.centered-on-social-i {
    /* height: 38px; */
    /* width: 162px; */
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: 3.8px;
    text-align: left;
}

.centers-the-analysis {
    color: #908d95;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 10px;
    text-align: left;
}

.step-3 {
    margin-top: 30px;
    margin-left: 31px;
    display: flex;
}

.step-3-img {
    height: 77px;
    width: 77px;
    margin-top: 14px;
}

.used-by-hr {
    color: #5f5f5f;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: left;
}

.already-used-by-hr-p {
    /* height: 113px; */
    /* width: 188px; */
    color: #908d95;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 10px;
    text-align: left;
}

.login-button {
    margin: 0 50px;
}


@media (max-width: 425px) {
    .step-img {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 320px) {
    .step-1 {
        margin-top: 52px;
        margin-left: 11px;
        display: flex;
    }

    .step-2 {
        margin-top: 30px;
        margin-left: 11px;
        display: flex;
    }

    .step-3 {
        margin-top: 30px;
        margin-left: 11px;
        display: flex;
    }
}