.question-page {
  text-align: left;
  padding: 51px;
}

.question {
  height: auto;
  /* width: 272px; */
  color: #3C3C3B;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 50px;
}

.quiz-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.quiz-num {
  height: 20px;
  /* width: 272px; */
  color: #006cb5;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  /* margin-bottom: 10px; */
  flex-grow: 1;
}

.answer {
  display: inline-flex;
  height: auto;
  /* widows: 272px; */
  margin-bottom: 30px;
}

.oval {
  box-sizing: border-box;
  height: 21px;
  width: 21px;
  border: 1px solid #908D95;
  /* opacity: 0.3; */
  border-radius: 20px;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0px;
  padding-top: 2px;
  position: absolute;
  margin-left: 20px;
  margin-top: 1px;
  background: white;
  display: none;
}

.select-answer {
  min-width: 31px;
  height: auto;
  margin-right: 19.5px;
  border: 1px solid #908D95;
  opacity: 0.3;
  border-radius: 30px;
  outline: none;
}

.select-answer-active {
  min-width: 31px;
  height: auto;
  color: white;
  margin-right: 19.5px;
  border-radius: 30px;
  background-color: #5FFFCD;
  border: none;
  outline: none;
}

.select-answer,
.select-answer-active,
.bottom-button,
.bottom-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-desc {
  padding: 10px 10px 10px 0;
  height: auto;
  /* width: 212px; */
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.answers {
  margin-bottom: 100px;
}

.answer-list {
  display: grid;
}

.question-line {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #908D95;
  opacity: 0.3;
}

.bottom-group {
  height: 150px;
  /* width: 375px; */
  width: 100%;
  padding: 0;
  position: fixed;
  bottom: 0px;
  background: white;
}

.row-1 {
  display: flex;
  margin: 20px 20px 0 20px;
  justify-content: space-around;
}

.explain {
  height: 40px;
  /* width: 219px; */
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  text-align: left;
  align-items: center;
}

.next-question {
  box-sizing: border-box;
  height: 41px;
  width: 135px;
  border: 1px solid #908D95;
  opacity: 0;
  background: white;
  border-radius: 40px;
  outline: none;
}

.to-word {
  box-sizing: border-box;
  height: 41px;
  width: 135px;
  border: 1px solid #908D95;
  opacity: 0;
  background: white;
  border-radius: 40px;
  outline: none;
  display: none;
}

.bottom-buttons {
  display: flex;
  margin: 10px 20px 10px 20px;
  justify-content: space-around;
}

#bottom-div-1 {
  height: 45px;
  width: 162px;
  display: flex;
}

#bottom-div-2 {
  height: 45px;
  width: 162px;
  opacity: 0.5;
  display: flex;
}

.bottom-button-active {
  height: 45px;
  width: 30px;
  border-radius: 30px;
  background-color: #5FFFCD;
  border: none;
  outline: none;
  color: white;
}

.bottom-button {
  box-sizing: border-box;
  height: 46px;
  width: 31px;
  border: 1px solid #908D95;
  opacity: 0.3;
  border-radius: 30px;
  outline: none;
}

.choice {
  height: 25px;
  width: 102px;
  color: #5F5F5F;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 10px 0 10px 20px;
}

.notifications {
  box-sizing: border-box;
  height: 21px;
  width: 21px;
  border: 1px solid #908D95;
  border-radius: 20px;
  /* opacity: 0.3; */
  position: absolute;
  margin-left: 20px;
  margin-top: 1px;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0px;
  padding-top: 2px;
  background: white;
  display: none;
}

#progressBarFrame {
  height: 5px;
  margin: 20px 20px 0 20px;
  border-radius: 10px;
  background-color: rgba(144, 141, 149, 0.1);
}

#progressBar {
  height: 5px;
  width: 10px;
  border-radius: 10px;
  background-color: #E14E12;
}

@media (min-width: 1024px) {

  .quiz-num,
  .answer-desc,
  .explain,
  .choice {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media (max-width: 320px) {
  .question-page {
    padding: 50px 20px;
  }

  .choice {
    margin: 10px 0;
  }
}

@media (max-width: 280px) {
  .choice {
    width: 85px;
  }
}