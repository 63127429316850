.bottom-menu {
    width: 100%;
    height: 61px;
    bottom: 0;
    position: fixed;
    background-color: #ffffff;
}

.menu-line {
    box-sizing: border-box;
    height: 2px;
    border: 1px solid #908D95;
    opacity: 0.3;
}

.menu-items {
    display: flex;
    justify-content: space-around;
    padding-top: 18px;
}

.menu-items svg {
    width: 30px;
    height: 30px;
}

.menu-items svg path,
.menu-items svg g {
    fill: rgb(144, 141, 149);
}

.menu-items svg.selected path {
    fill: #d755a2;
}

.notification-icon path {
    fill: #d5d1de !important;
}