.main-heading {
    font-size: 24px;
    color: #006cb5;
}

.quotation-table-modal #chartBlock {
    max-width: 700px;
    margin: 0 auto;
}

.quotation-table {
    border-collapse: collapse;
}

.quotation-table td,
.quotation-table th {
    border: 1px solid lightgrey;
    padding: 12px 16px;
}

.quotation-table th {
    font-weight: 600 !important;
}

.quotation-table-modal .header {
    font-size: 20px;
}

.quotation-table-modal .header .title1 {
    color: #006cb5;
}

.quotation-table-modal .header .title2 {
    color: #d755a2;
}

div#actions {
    position: absolute;
    top: 0;
    right: 0;
    margin: 24px;
}

#printBtn:hover {
    color: white;
}