.sort-page {
  padding-top: 10px;
}

.main {
  padding: 40px 52px 42px 51px;
  margin-bottom: 50px;
  /* text-align: left; */
}

.sort-title {
  display: flex;
  text-align: left;
  color: #3C3C3B;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: -30px;
}

.rooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 80px;
  background-color: #FFFFFF;
}

.rooter .line {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #908D95;
  opacity: 0.3;
  margin-left: 0px;
}

.lines-numbers {
  height: 657px;
  width: 23px;
  padding-top: 60px;
}

.content {
  display: flex;
  text-align: left;
}

.number {
  height: 41px;
  width: 23px;
  opacity: 0.6;
  color: #908D95;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 56.5px;
  text-align: right;
  margin-bottom: 15px;
}

.sort-list {
  margin-left: -12px;
  margin-top: 50px;
}

.icon {
  height: 27px;
  width: 27px;
}

.submit {
  height: 40px;
  width: 116px;
  border-radius: 40px;
  background-color: #5FFFCD;
  margin-top: 20px;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  padding-top: 12px;
  text-align: center;
  float: right;
  margin-right: 20px;
  cursor: pointer;
}

.button-icon {
  height: 16px;
  width: 16px;
  margin-right: 13px;
}

.sort-item {
  height: 41px;
  width: 210px;
  border: 1px solid rgba(144, 141, 149, 0.3);
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  margin-top: 15.5px;
  background-color: white;
  text-align: left;
  padding: 13px 36px 12px 10px;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  outline: none;
  cursor: pointer;
}

@media (max-width: 320px) {
  .main {
    padding: 40px 25px 42px 25px;
  }
}

@media (max-width: 280px) {
  .main {
    padding: 40px 15px 42px 15px;
  }
}

@media (min-width: 1024px) {

  .sort-item {
    font-size: 16px;
  }
}