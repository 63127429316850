.slide-frame {
    text-align: center;
}

.slide {
    margin-bottom: 64px;
}

.slide-frame .slick-dots {
    bottom: -42px;
}

.slide-frame .slick-dots li {
    border: 1px solid white;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin: 0 8px;
    opacity: 0.3;
}

.slide-frame .slick-dots li button::before {
    display: none;
}

.slide-frame .slick-dots li.slick-active {
    background: #00cfd7;
    opacity: 1 !important;
    border: 0;
}

.lady-picture {
    max-height: 300px;
    max-width: 300px;
    /* margin-top: -40px; */
    float: right;
    /* position: absolute; */
    right: 0;
    top: 0;
    /* transform: translate(-50%, 25%); */
}

.title {
    /* height: 152px; */
    /* width: 272px; */
    text-align: left;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 3rem;
    letter-spacing: 0;
    line-height: 1.5;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* max-width: 50%; */
}

.call-to-action {
    margin-top: 2.2rem;
    font-size: 1rem;
    font-family: Montserrat;
    color: #ffffff;
    text-align: left;
}

.slide-description {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 1.5rem;
}

.title>b {
    font-weight: bold;
}

.explain-slide {
    /* height: 96px; */
    /* width: 272px; */
    color: #ffffff;
    font-family: Montserrat;
    /* font-size: 1.5rem; */
    letter-spacing: 0;
    /* line-height: 2rem; */
    text-align: left;
    margin-top: 30px;
}

.explain-slide>p {
    /* font-weight: bold; */
    width: 170px;
}

.pointer-frame {
    display: flex;
    height: 12px;
    margin-top: 30px;
    justify-content: center;
}

.pointer {
    box-sizing: border-box;
    height: 13px;
    width: 13px;
    border: 1px solid #ffffff;
    opacity: 0.3;
    border-radius: 50%;
    margin-right: 11.5px;
}

.pointer-active {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: none;
    background-color: #00cfd7;
    margin-right: 11.5px;
}

.slide-img-sm {
    max-width: 300px;
    margin-top: 70px !important;
    max-height: 300px;
    margin: auto;
}

@media (max-width: 768px) {
    .lady-picture {
        height: 200px;
        width: 200px;
        transform: translate(0%, 0%);
    }

    .title {
        font-size: 3rem;
        /* line-height: 4rem; */
        margin-bottom: 24px;
    }


}

@media (max-width: 767px) {
    .intro-btns #login-modal-container {
        margin: 0 !important;
        margin-bottom: 1.5rem !important;
    }

    .title {
        font-size: 2.5rem;
        /* line-height: 1.5; */
        margin-bottom: 24px;
        max-width: unset;
    }
}

@media (max-width: 425px) {


    .swiper-slide img:not(.lady-picture) {
        width: 240px;
        height: 240px;
        margin: 0 auto;
    }

    .title {
        font-size: 2rem;
        /* line-height: 1.5; */
        margin-bottom: 24px;
        max-width: unset;
    }

    .slide-description {
        font-size: 1.2rem;
    }
}

/* for sm */
@media (max-width: 375px) {
    .title {
        text-align: left;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 2rem;
        letter-spacing: 0;
        /* line-height: 40px; */
        max-width: unset;
    }

    .lady-picture {
        height: 163px;
        width: 185px;
        margin-top: 0px;
        float: right;
    }
}

@media (max-width: 320px) {
    .title {
        /* width: 260px; */
        text-align: left;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 1.5rem;
        letter-spacing: 0;
        /* line-height: 32px; */
        /* margin-left: -20px; */
        max-width: unset;
    }
}

@media (max-width: 280px) {
    .title {
        height: 100px;
        width: 220px;
        text-align: left;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 1.25rem;
        letter-spacing: 0;
        /* line-height: 32px; */
        margin-left: -20px;
    }

    .lady-picture {
        height: 163px;
        width: 185px;
        margin-top: 0px;
        float: right;
    }
}

.intro-btns {
    /* position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translate(-50%); */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}