.menu-flags {
  outline: none !important;
}

.flag-select__option__icon {
  display: none;
}
.flag-select__option__label {
  font-size: 16px;
}

.flag-select__btn {
  outline: none !important;
}