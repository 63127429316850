.App {
  text-align: center;
  font-family: 'Poppins';
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.NavBar {
  background-color: #4285f4;
  /* position: absolute; */
  height: 40px;
  /* width: 100vw; */
  text-align: right;
  padding-right: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wwt-btn {
  box-sizing: border-box;
  /* height: 41px; */
  /* height: 100%; */
  border-radius: 40px;
  /* margin-top: 20px; */
  color: #ffffff;
  /* z-index: 10000; */
  font-family: Poppins;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1.5px;
  /* line-height: 24px; */
  padding: 16px;
  text-transform: uppercase;
  /* width: 150px; */
  cursor: pointer;
}

.font-montserrat {
  font-family: Montserrat !important;
}

.font-poppins {
  font-weight: Poppins;
}

.wwt-btn.wwt-btn-outline {
  border: 1px solid #c4bcce;
}

.wwt-btn.btn-primary {
  background-color: #006CB5 !important;
}

.wwt-btn.wwt-btn-pink {
  background: #d755a2 !important;
}

.wwt-btn.wwt-btn-blueli {
  background-color: #126297 !important;
}


.wwt-btn.wwt-btn-cyan {
  background: #00cfd7;
}

.wwt-btn.wwt-btn-green {
  background-color: green;
}

.wwt-btn.wwt-btn-red {
  background-color: red;
}

.wwt-container {
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
}

.wwt-btn.flat {
  box-shadow: none;
}

.wwt-select {
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0px;
  /* outline: 0 !important; */
  box-shadow: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.tc-primary {
  color: #006cb5;
}

.bgc-primary {
  background-color: #006cb5;
}

.md-form .prefix {
  left: 0.25rem;
}

.action-btn {
  background: transparent !important;
  color: grey;
  box-shadow: none !important;
  outline: none;
  border: 0 !important;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-btn:focus {
  background: transparent !important;
  outline: none;
}

.action-btn:hover,
.action-btn:hover .icon {
  color: #d755a2;
}

.action-btn .icon {
  background: #E7E7E7;
  padding: 10px;
  color: darkgrey;
  border-radius: 24px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  /* line-height: 24px; */
  vertical-align: middle;
}

.width-unset {
  width: unset !important;
}

.action-btn .icon g {
  fill: darkgrey;
  background: #E7E7E7;
}

.action-btn .icon+.text {
  margin-left: 16px;
}

.action-btn {
  margin-right: 16px;
}

.action-btn.disabled .icon {
  color: lightgrey;
  cursor: not-allowed;
  pointer-events: auto;
}

.action-btn.disabled .icon g {
  fill: lightgrey;
}

.action-btn:not(.disabled):hover .icon,
.rc-dropdown-open .icon {
  background: #d755a2;
  color: white;
}

.action-btn:not(.disabled):hover .icon g,
.rc-dropdown-open .icon g {
  fill: white;
  background: #d755a2;
}

.rc-dropdown-menu {
  border-radius: 8px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2) !important;
  border-width: 0 !important;
}

.rc-dropdown-menu-item-active {
  background-color: transparent !important;
}

table.table thead th {
  vertical-align: middle;
  padding: 4px 16px;
}

table.table tbody td {
  vertical-align: middle;
  padding: 4px 16px;
  height: 50px;
}

table.table.row-clickable tbody tr {
  cursor: pointer;
  border: 2px solid transparent;
  border-left: 0;
  border-right: 0;
}

table.table.row-clickable tbody tr:hover {
  border-radius: 4px;
  border-color: rgba(144, 141, 149, 0.2);
  box-shadow: 0 1px 8px 0 rgb(0 108 181 / 20%);
}

table.table thead tr:first-child th {
  vertical-align: middle;
  padding-top: 16px;
}

table.table thead tr:last-child th {
  vertical-align: middle;
  padding-bottom: 16px;
}

.admin-container .nav-tabs .nav-link {
  font-size: 20px;
  /* padding: 1rem 2rem; */
  padding: 22px 30px;
  /* background-color: #E7E7E7; */
  color: #5F5F5F !important;
  font-weight: bold;
  border-width: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.admin-container .nav-tabs {
  border: 0;
}

.admin-container .tab-content {
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
  border-top-right-radius: 8px;
}

.admin-container .missions-header {
  height: 66px;
  color: #5F5F5F;
  font-family: Poppins;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 16px;
  margin-right: 16px;
}

.rc-dropdown-menu {
  min-width: 250px;
}

.text-transform-none {
  text-transform: none !important;
}

.search-input {
  padding: 8px 24px;
  border: 0;
  border-radius: 24px;
  outline: 0;
}

.typeahead-feedback {
  color: rgb(235, 54, 54);
  font-size: 14px;
  /* margin-bottom: 20px; */
  text-align: left;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.modal {
  overflow: auto !important;
}