.all-matching-btn {
    background: #fff !important;
    border-radius: 100px;
    padding: 8px 24px 8px 12px;
    color: white;
    color: grey;
    box-shadow: none!important;
    border: 0!important;
    font-size: 14px;
    font-family: "Poppins";
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
}