.results-header {
  height: 160px;
  padding: 40px 20px;
  background: linear-gradient(135.13deg, #006CB5 0%, #3E88D4 100%);
  ;
  display: flex;
  justify-content: space-between;
}

.results-page {
  background: linear-gradient(135.13deg, #006CB5 0%, #3E88D4 100%);
}

.results-body {
  background: white;
}

.results-left {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: rgba(60, 60, 59, 0.3);
  text-align: center;
  padding-top: 16px;
}

.upload-icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.results-help-bold {
  font-weight: bold;
  display: inline;
}

.results-right {
  height: 80px;
  flex: 1 1 100%;
  margin-left: 10px;
  /*  */
  display: flex;
  align-items: center;
}

.results-header-title {
  height: 28px;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  /* margin: auto; */
}

.results-right-second {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  /* display: none; */
}

.results-edit-profile {
  box-sizing: border-box;
  height: 41px;
  width: fit-content;
  border: 1px solid #FFFFFF;
  border-radius: 40px;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 15px;
  padding: 13px 30px;
  justify-content: space-between;
  display: flex;
}


.results-softskill-text {
  background-color: transparent;
  color: #0a66c2;
  box-shadow: inset 0 0 0 1px #0a66c2;
  font-size: 1.2rem;
  min-height: 2.2rem;
  padding: .6rem 1.2rem;
  line-height: 1.2rem;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: 167ms;
  align-items: center;
  border: none;
  box-sizing: border-box;
  font-weight: 600;
  font-family: inherit;
  display: inline-flex;
  justify-content: center;
  min-width: 6.4rem;
  max-width: 480px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-radius: 1.59rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin: .2rem;
}

.multi-lang-menu {
  width: 100px;
}

.multi-lang-menu .flag-select.menu-flags {
  display: flex;
}

.edit-icon {
  height: 16px;
  width: 16px;
  margin-right: 15px;
}

.settings-icon-frame {
  box-sizing: border-box;
  height: 41px;
  width: 41px;
  border: 1px solid #FFFFFF;
  border-radius: 40px;
  padding-top: 7px;
}

.settings-icon {
  height: 16px;
  width: 16px;
}

.results-first-page {
  padding: 8px 20px 41px 20px;
}

.results-rectangle-1 {
  display: flex;
  /* text-align: left; */
  padding: 28px 20px 41px 20px;
  justify-content: space-between;
}

.results-rectangle-1-text {
  width: 60%;
  color: #908D95;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: left;
}

.results-rectangle-1-text>b {
  font-weight: 900;
}

.results-rectangle-1-picture {
  width: 118px;
  border-radius: 1px 40px 1px 40px;
  background-color: #FAB83B;
  margin-left: 8px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}

.results-lady-picture {
  width: 100%;
  /* height: 183px; */
  /* width: 149px; */
  /* margin-left: -30px;
  margin-top: 50px; */
  transform: scale(1.2);
}

.results-rectangle-2 {
  margin-top: 20px;
}

.results-diagram-title {
  color: #D755A2;
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
}

.results-diagram-button {
  box-sizing: border-box;
  height: 31px;
  width: 81px;
  border: 1px solid #908D95;
  border-radius: 40px;
  display: flex;
  padding: 7px 8px;
  justify-content: space-between;
}

.help-icon {
  height: 16px;
  width: 16px;
}

.results-help-content {
  height: 15px;
  width: 30px;
  /* opacity: 0.3; */
  color: #908D95;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}

.results-second {
  height: auto;
  background-color: #FDF6F3;
  padding: 38px 20px 0 20px;
}

.results-second-bar {
  height: 20px;
  width: 2px;
  background-color: #D755A2;
  margin-right: 13px;
}

.results-second-title {
  height: 24px;
  width: 275px;
  color: #5F5F5F;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.results-accordion-frame {
  text-align: left;
  padding-top: 19px;
}

.results-third-page {
  padding: 46px 20px 0 20px;
  text-align: left;
}

.results-third-bar {
  height: 34px;
  width: 2px;
  background-color: #D755A2;
  margin-top: 2px;
}

.results-third-title {
  height: 43px;
  width: 328px;
  color: #5f5f5f;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 15px;
  margin-bottom: 30px;
}

.result-fourth-title {
  color: #D755A2;
  text-transform: uppercase;
}

.help-modal-title {
  height: 40px;
  color: #3C3C3B;
  font-family: Poppins;
  font-size: 30px !important;
  font-weight: 800 !important;
  letter-spacing: 0;
  line-height: 39px;
  padding: 10px 10px;
}

.help-modal-subtitle {
  color: #636361;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin: 15px 0;
}

.help-modal-body {
  text-align: left;
  color: #908D95;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  padding: 20px 30px !important;
}

.invite-modal-title {
  height: 40px;
  color: #3C3C3B;
  font-family: Poppins;
  font-size: 24px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 35px;
  padding: 15px 0 15px 20px;
}

.invite-modal-header {
  border-bottom: none !important;
}

.invite-modal-frame {
  height: 130px;
  /* width: 310px; */
  border-radius: 8px;
  margin-top: 14px;
  background-color: #FAB83B;
  padding: 21px 17px 18px 17px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
}

.invite-modal-frame-text {
  width: 184px;
  color: #3C3C3B;
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
}

.share-modal-frame-text {
  width: 190px;
  padding-top: 18px;
}

.invite-modal-avatar {
  height: 110px;
  width: 126px;
  margin-right: -23px;
  margin-top: 10px;
}

.share-modal-avatar {
  height: 140px;
  width: 100px;
}

.invite-modal-send {
  /* padding: 0 20px; */
  margin-top: 39px;
}

.invite-send-subtitle {
  color: #D755A2;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.invite-send-comment {
  color: #9B989F;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 7px;
  margin-bottom: 45px;
}

.invite-modal-button {
  height: 50px;
  /* width: 272px; */
  border-radius: 40px;
  background-color: #D755A2;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  padding: 18px;
  margin-top: 30px;
}

.md-form {
  margin-bottom: 2px !important;
}

.custom-checkbox.invite-checkbox {
  box-sizing: border-box;
  border: 1px solid #908D9555;
  border-radius: 8px;
  padding: 10px 10px 10px 20px;
  margin-top: 30px;
  display: flex !important;
}

.custom-control-label {
  font-size: 12px;
  color: #908D95;
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
}

.custom-control-input {
  position: relative !important;
}

.copied {
  font-size: 20px;
  margin-left: 15px;
  margin-top: 40px;
}

.no-copied {
  display: none;
}

.invite-label {
  color: #5F5F5F;
  font-size: 16px;
  font-family: Poppins;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 40px;
  padding-left: 20px;
}

.share-button-group {
  justify-content: space-evenly;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}

.share-button-group .disabled {
  opacity: 0.3;
}

.linkedin-share-block .btn-linkedin {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  background: transparent;
  color: grey;
  font-size: 12px;
}

.linkedin-share-block .btn-linkedin:focus {
  outline: 0;
}

.social-share-button {
  outline: none !important;
  display: block;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 18px !important;
  text-align: center;
  color: #5f5f5f !important;
  font-family: Poppins !important;
}

.react-share__ShareButton>svg {
  display: block;
  margin: auto;
}

.react-share__ShareButton:nth-of-type(4)>svg>circle {
  fill: #FF8891;
}

.clipboard {
  display: flex;
  margin-top: 38px;
  margin-bottom: 40px;
  color: #FFFFFF;
}

.clipboard-icon {
  margin: auto;
}

.clipboard-button {
  height: 48px;
  width: 160px;
  border-radius: 25px;
  background-color: #006CB5;
  display: flex;
  text-align: center;
  padding: 0 18px;
  margin: auto;
  justify-content: space-around;
  align-items: center;
}

.clipboard-button>p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  margin: auto;
}

.improvement-area-block {
  font-size: 14px;
  font-family: 'poppins';
  color: #908D95;
  padding-bottom: 30px;
}

@media (max-width: 320px) {
  .results-edit-profile {
    padding: 13px 20px;
  }
}

@media (max-width: 280px) {
  .results-edit-profile {
    padding: 13px 7px;
  }

  .results-third-title {
    font-size: 14px;
  }

  .results-rectangle-1-text {
    font-size: 12px;
  }

  .social-share-button {
    font-size: 9px !important;
  }

  .share-modal-frame-text {
    padding-top: 0;
  }

}

@media(min-width: 280px) and (max-width: 768px) {
  .results-rectangle-1-text {
    font-size: 14px;
  }
}