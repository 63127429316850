.word-button {
    box-sizing: border-box;
    height: 41px;
    width: fit-content;
    /* padding: 5px; */
    border: 1px solid #908D95;
    opacity: 0.3;
    border-radius: 40px;
    margin: 0 16px 12px 0;
    color: #000000;
    font-family: Montserrat;
    font-size: 12px;
    letter-spacing: 0;
    padding: 11px 20px;
    line-height: 15px;
    outline: none !important;
    cursor: pointer;
}

.word-button.selected {
    border: 1px solid transparent;
    color: white;
    font-family: Montserrat;
    background-color: #00CFD7;
    opacity: 1;
}

.word-button.max:not(.selected) {
    cursor: not-allowed;
}

.next-btn {
    box-sizing: border-box;
    border: 1px solid #908D95;
    background-color: #FFFFFF;
    border-radius: 40px;
    padding: 0 35px;
    outline: none;
    color: #908D95;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    outline: none !important;
}

.next-btn:disabled {
    opacity: 0.3;
}

@media (min-width: 1024px) {

    .word-button {
        font-size: 16px;
    }
}