.circle {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin: 0 auto;
}

.circle.red {
    background-color: red;
}

.cirlce.green {
    background-color: green;
}

.circle.orange {
    background-color: orange;
}