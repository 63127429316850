.menu-item {
    height: 30px;
    border-radius: 15px;
    color: white;
    padding: 4px 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.menu-item:hover {
    color: white;
}

.menu-item.active {
    background: white;
    color: #006CB5;
}

.menu-item.disabled {
    cursor: not-allowed;
}

.admin-header {
    background-color: #006CB5;
    min-height: 40px;
}

.profile-btn {
    height: 50px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-left: 32px;
    padding-right: 32px;
}