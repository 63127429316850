table.missions th {
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    border: none;
}

table.missions td {
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(163, 172, 189, 0.1);
}