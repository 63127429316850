.barometer-question {
    font-size: 20px;
    margin-bottom: 8px;
}

.selected-count {
    border: 1px solid lightgrey;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}