.progress-accordion {
  padding-top: 10px;
  padding-bottom: 30px;
}

.progress-accordion-row-1 {
  display: flex;
  justify-content: space-between;
}

.accordion-icon-frame {
  box-sizing: border-box;
  height: 31px;
  width: 31px;
  border: 1px solid #908D95;
  opacity: 0.3;
  border-radius: 40px;
  text-align: center;
  padding: 2px;
}

.accordion-content {
  color: #5F5F5F;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 20px;
}

.results-accordion-progressbar {
  width: 90%;
}

.softskill {
  height: 20px;
  width: 240px;
  color: #5F5F5F;
  font-family: Montserrat;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
}

.softskill-percent {
  height: 16px;
  width: 55px;
  opacity: 0.5;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
}

.softskill-progressbar-frame {
  height: 5px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(144,141,149,0.1);
  margin-top: 15px;
}

.softskill-prgressbar-content {
  height: 5px;
  border-radius: 10px;
  background-color: #D755A2;
}
