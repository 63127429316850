.main {
  padding: 40px 52px 42px 51px;
  margin-bottom: 50px;
}

.select-title {
  display: flex;
  text-align: left;
  color: #3C3C3B;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

.words {
  /* width: 287px; */
  display: flex;
}

.word-button {
  box-sizing: border-box;
  height: 41px;
  width: fit-content;
  /* padding: 5px; */
  border: 1px solid #908D95;
  opacity: 0.3;
  border-radius: 40px;
  margin: 0 16px 12px 0;
  color: #000000;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  padding: 11px 20px;
  line-height: 15px;
  outline: none !important;
  cursor: pointer;
}

.word-button-select {
  box-sizing: border-box;
  height: 41px;
  width: auto;
  /* padding: 5px; */
  border: none;
  border-radius: 40px;
  margin: 0 16px 12px 0;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  padding: 11px 20px;
  line-height: 15px;
  background-color: #00CFD7;
  outline: none !important;
  cursor: pointer;
}

.button-content {
  height: 30px;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  padding: 7px 10px;
  line-height: 15px;
  text-align: center;
}

.select-rooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 80px;
  background-color: #FFFFFF;
}

.select-line {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #908D95;
  opacity: 0.3;
}

.bottom-content {
  height: 40px;
  margin-top: 18px;
  margin-bottom: 22px;
  margin-left: 2px;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
}

.select-number {
  height: 40px;
  min-width: 50px;
  color: #3C3C3B;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
}

.bottom-text {
  height: 18px;
  min-width: 135px;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 11px;
  margin-left: 10px;
  text-align: left;
}

.next-button {
  box-sizing: border-box;
  height: 41px;
  border: 1px solid #908D95;
  background-color: #FFFFFF;
  opacity: 0.3;
  border-radius: 40px;
  padding: 0 35px;
  outline: none;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  outline: none !important;
}

.next-button-active {
  box-sizing: border-box;
  height: 41px;
  border: 1px solid #908D95;
  background-color: #FFFFFF;
  /* opacity: 0.3; */
  border-radius: 40px;
  padding: 0 35px;
  outline: none;
  color: #908D95;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  outline: none !important;
}

@media (min-width: 1024px) {

  .word-button,
  .word-button-select {
    font-size: 16px;
  }
}

@media (max-width: 280px) {
  .bottom-content {
    padding: 0 5px;
    justify-content: space-around;
  }

  .bottom-text {
    margin-left: 0;
  }

  .next-button {
    padding: 0 10px;
  }

  .next-button-active {
    padding: 0 10px;
  }
}