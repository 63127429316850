.question-block .answer .select-answer {
    min-width: 31px;
    height: auto;
    margin-right: 19.5px;
    border: 1px solid #908D95;
    opacity: 0.3;
    border-radius: 30px;
    outline: none;
}

.question-block .answer.selected .select-answer {
    min-width: 31px;
    height: auto;
    color: white;
    margin-right: 19.5px;
    border-radius: 30px;
    background-color: #5FFFCD;
    border: none;
    outline: none;
    opacity: 1;
}

.question-block .oval {
    box-sizing: border-box;
    height: 21px;
    width: 21px;
    border: 1px solid #908D95;
    /* opacity: 0.3; */
    border-radius: 20px;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0px;
    padding-top: 2px;
    position: absolute;
    margin-left: 20px;
    margin-top: 1px;
    background: white;
    display: block !important;
}

.question-block .choice {
    width: unset;
}

.question-block .notifications {
    box-sizing: border-box;
    height: 21px;
    width: 21px;
    border: 1px solid #908D95;
    border-radius: 20px;
    /* opacity: 0.3; */
    position: absolute;
    margin-left: 20px;
    margin-top: 1px;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0px;
    padding-top: 2px;
    background: white;
    display: block;
}

.question-block .next-question-btn {
    box-sizing: border-box;
    height: 41px;
    width: 135px;
    border: 1px solid #908D95;
    background: white;
    border-radius: 40px;
}