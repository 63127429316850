.invite-rect {
    margin: 17px 20px 30px 20px;
    padding: 35px 27px 41px 27px;
    color: #ffffff;
    font-family: Poppins;
    text-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #006CB5;
}

.et-maintenant {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 9px;
}

.invite-rect-title {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
    margin-bottom: 26px;
}

.two-persons {
    height: 116px;
    width: 200px;
    margin: auto;
    margin-bottom: 21px;
}

.invite-rect-subtitle {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    width: 200px;
    margin: auto;
    margin-bottom: 31px;
}

.calltoaction-rect-subtitle {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: auto;
    margin-bottom: 31px;
}

.share-rect {
    margin: 0 20px 40px 20px;
    padding: 35px 27px 41px 27px;
    color: #ffffff;
    font-family: Poppins;
    text-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #E87B9B;
}

.share-rect-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 32px;
}

.certificate-rect {
    margin: 0 20px 100px 20px;
    padding: 35px 27px 41px 27px;
    color: #ffffff;
    font-family: Poppins;
    text-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #0A66C2;
}

.linkedin-mark {
    height: 64px;
    width: 47px;
    margin: auto;
    margin-top: -75px;
}

.certificate-rect-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
}

.certificate-rect-line {
    height: 2px;
    width: 24px;
    background-color: #FFFFFF;
    margin: auto;
    margin-top: 14px;
}

.certificate-rect-content {
    width: 200px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 16px 0 25px 0;
    margin: auto;
}

.certificate-rect-button {
    height: 50px;
    width: 200px;
    border-radius: 25px;
    background-color: #E87B9B;
    margin: auto;
    display: flex;
    align-items: center;
}

.certificate-rect-button>p {
    color: #ffffff;
    font-size: 12px;
    font-family: Poppins;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    display: flex;
    margin: auto;
    justify-content: center;
}

@media (max-width: 320px) {
    .share-rect-title {
        font-size: 19px;
    }
}