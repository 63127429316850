.team-building-missions .row-disabled {
    cursor: wait !important;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0 !important;
    opacity: 0.5;
}

th.cpi {
    max-width: 190px;
}