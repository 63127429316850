.confirmation-modal .modal-header,
.confirmation-modal .modal-footer {
    border: 0;
}

.confirmation-modal .modal-body {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
    text-align: start;
}

.confirmation-modal .btn-yes,
.confirmation-modal .btn-no {
    padding: 16px 32px;
}

.confirmation-modal .btn:hover {
    color: white !important;
}

.confirmation-modal .btn-no {
    background-color: #969696 !important;
}