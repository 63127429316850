.all-matchings .last-name-1,
.all-matchings .last-name-2 {
    width: 150px;
}

.all-matchings-modal .search-input {
    padding: 0 8px;
    border-radius: 8px;
    outline: 0;
    border: 0;
}