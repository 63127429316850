.pro .navbar-brand {
    font-weight: 600;
    color: #076cb5;
    font-size: 24px;
}

.pro .body {
    min-height: calc(100vh - 196px);
}

.pro .message {
    font-size: 24px;
    line-height: 1.8;
    text-align: start;
    color: #585858;
}

.pro-login-btn>div>div:first-of-type {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}